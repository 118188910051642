export default [
    {
        id: 1,
        name:'All Posts'
    },
    {
        id: 2,
        name:'Crime and Alerts'
    },
    {
        id: 3,
        name:'Upcoming Events'
    },
    {
        id: 4,
        name:'Lost and Found'
    },
]